import { z } from 'zod';

export const quickActionSchema = z.object({
  status: z.boolean(),
  toggleTag: z.string(),
});

type QuickAction = z.infer<typeof quickActionSchema>;

export default QuickAction;
